






































import {
  defineAsyncComponent, defineComponent, onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { email, required } from 'vee-validate/dist/rules';

import { useOutOfStock, usePageHelpers } from '~/composables';

const ValidationObserver = defineAsyncComponent(() => import('vee-validate').then((mod) => mod.ValidationObserver));
const ValidationProvider = defineAsyncComponent(() => import('vee-validate').then((mod) => mod.ValidationProvider));

export default defineComponent({
  name: 'OutOfStockForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    SfInput: () => import('@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue'),
  },
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
    categoryId: {
      type: [String, Number],
      default: null,
    },
  },

  emits: ['is-success'],

  setup(props, { emit }) {
    const { requestStockNotification } = useOutOfStock();
    const { isSearchPage } = usePageHelpers();

    const form = ref({
      email: '',
    });
    const error = ref();
    const loading = ref(false);

    const submitForm = async () => {
      error.value = '';
      loading.value = true;

      const result = await requestStockNotification({
        email: form.value.email,
        productId: props.productId,
        categoryId: props.categoryId,
        categoryProduct: !isSearchPage && props.categoryId ? 'category' : 'product',
      });

      if (result.data?.result?.ok) {
        emit('is-success');
      } else if (result.data?.result?.error) {
        error.value = result.data.result.error;
      } else {
        error.value = result.data;
      }

      loading.value = false;
    };

    onMounted(() => {
      import('vee-validate').then(({ extend }) => {
        extend('email', {
          ...email,
          message: 'Please provide a valid e-mail address.',
        });

        extend('required', {
          ...required,
          message: 'Field is required',
        });

        return true;
      }).catch((err) => {
        console.error('Error loading vee-validate:', err);
      });
    });

    return {
      form,
      error,
      loading,
      submitForm,
    };
  },
});
